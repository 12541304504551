'use client';

import BasicSiteHeader from '~/components/furniturechoice/basic-site-header';

export default function Error({ error }: { error: Error & { digest?: string } }): JSX.Element {
  return (
    <>
      <BasicSiteHeader>Server Error</BasicSiteHeader>

      <div className="container mt-6 flex flex-col gap-y-6">
        <p className="text-center">Something went wrong! Please try again later.</p>
        <p className="text-center">If the issue persists, please contact support and provide this error ID:</p>
        <p className="text-center font-medium">{error.digest}</p>
      </div>
    </>
  );
}
