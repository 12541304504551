import { PropsWithChildren } from 'react';
import Link from 'next/link';
import Logo from '~/components/furniturechoice/logo';
import SectionHeader from '~/components/furniturechoice/section-header';

export default function BasicSiteHeader({ children }: PropsWithChildren): JSX.Element {
  return (
    <header className="container">
      <div className="flex items-end py-5">
        <Link className="w-[168px] lg:w-[156px]" href="/">
          <Logo className="w-full" />
        </Link>

        <Link className="ml-auto text-14 text-salmon-4" href="/">
          {'< Continue shopping'}
        </Link>
      </div>

      <div className="flex flex-col gap-y-5 py-10 lg:items-center lg:gap-y-4 lg:pb-8">
        <SectionHeader as="h1">{children}</SectionHeader>
      </div>
    </header>
  );
}
